import { graphql } from 'gatsby'
import React from 'react'
import { PrivacyPageQuery } from '/types/graphql-types'
import type { HeadProps } from 'gatsby'
import SEO from '~/components/seo'
import Layout from '~/components/layout'
import { css } from '@emotion/react'
import { PAGE_HEADING_STYLE } from '~/styles/common'

export const pageQuery = graphql`
  query PrivacyPage {
    markdownRemark(frontmatter: { slug: { eq: "privacy" } }) {
      html
    }
  }
`

interface Props {
  data: PrivacyPageQuery
}

const PrivacyPage: React.FC<Props> = ({ data }) => {
  const { markdownRemark } = data
  const { html } = markdownRemark!

  return (
    <Layout>
      <div css={style.wrapper}>
        <div dangerouslySetInnerHTML={{ __html: html! }} />
      </div>
    </Layout>
  )
}

const style = {
  wrapper: css`
    h2 {
      ${PAGE_HEADING_STYLE}
    }

    h3 {
      font-size: 100%;
      margin: 2em 0 1em 0;
    }

    > div {
      padding-bottom: 1em;
      p,
      ol {
        font-size: 86%;
      }
    }

    p {
      margin-bottom: 1em;
    }

    ol {
      li {
        margin-bottom: 0.25em;
      }
    }
  `,
}

export default PrivacyPage

const SEO_TITLE = `プライバシーポリシー`

export const Head: React.FC<HeadProps> = () => {
  return <SEO title={SEO_TITLE} />
}
